
import CyanPageWrapper, {
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";

import { municipios } from '@/modules/cyanRegions';

import { IonPage, IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon } from "@ionic/vue";

import {
  flagOutline,
  mailOpenOutline,
  trophyOutline
} from "ionicons/icons";

export default defineComponent({
  name: "Agente",
  components: {
    IonLabel,
    IonPage,
    IonIcon,
    IonTabs,
    IonTabBar,
    IonTabButton,
    CyanPageWrapper,
  },
  setup() {
    return {
      flagOutline,
      mailOpenOutline,
      trophyOutline
    };
  },
  data() {
    return {};
  },
  computed: {
    junta(): string {
      return (this as any).$route.params.junta || '??';
    },
    municipio(): string {
      if (this.seed in municipios) {
        const m = municipios[(this as any).seed];
				return  m.nombre + ', ' + m.nombrePadre;
      }
      return '';
    },
    baseUrl(): string {
      const p = (this as any).$route.params;

      return '/agente/' + p.ambito + '/' + p.junta + '/';

    },
    seed(): any {
      return (this as any).$route.params.ambito || "SV";
    },

    backRoute() {
      const u = store.state.userData.nivel || "";
      const seed = this.seed as string;

      // Si hay más de una junta en el centro implicado,
      // a la elección de junta

      const acj = store.state.allowedCentrosJuntas[seed];

      if (acj) {
        const j = (this as any).$route.params.junta;
        if (
          acj.datosJuntas &&
          acj.datosJuntas[j] &&
          acj.datosJuntas[j].centro_id &&
          acj.mapaCentrosJuntas[acj.datosJuntas[j].centro_id] &&
          acj.mapaCentrosJuntas[acj.datosJuntas[j].centro_id].length > 2
        )
          return "/agentePickJunta/" + seed + "/" + acj.datosJuntas[j].centro_id;

        // Si hay más de un centro, a la elección de centro

        if (acj.centros.length > 1) return "/agentePickCentro/" + seed;
      } // ACJ
      // Si el usuario tiene nivel municipal, a home; si no, a pickMpio

      if (u.length != 6) return "/agentePickMpio/" + seed.substr(0, 4);

      return "/home";
    },
  },
  methods: {
    telefonoGuay(t: string) {
      return t.substr(0, 4) + "-" + t.substr(4);
    },
  },
});
