<template>
  <ion-page
    ><cyan-page-wrapper
      title="Control Electoral"
      :backRoute="backRoute"
      nav1="CONTROL ELECTORAL"
      :nav2="'JRV ' + junta"
      :ambito="municipio"
    >
      <ion-tabs>
        <!-- eslint-disable vue/no-deprecated-slot-attribute -->
        <ion-tab-bar slot="top">
          <ion-tab-button tab="tab1" :href="baseUrl + 'jornada'">
            <ion-icon :icon="flagOutline" />
            <ion-label>Jornada</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="tab2" :href="baseUrl + 'conteo'">
            <ion-icon :icon="mailOpenOutline" />
            <ion-label>Conteo</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="tab3" :href="baseUrl + 'actas'">
            <ion-icon :icon="trophyOutline" />
            <ion-label>Actas</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </cyan-page-wrapper></ion-page
  >
</template>

<script lang="ts">
import CyanPageWrapper, {
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";

import { municipios } from '@/modules/cyanRegions';

import { IonPage, IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon } from "@ionic/vue";

import {
  flagOutline,
  mailOpenOutline,
  trophyOutline
} from "ionicons/icons";

export default defineComponent({
  name: "Agente",
  components: {
    IonLabel,
    IonPage,
    IonIcon,
    IonTabs,
    IonTabBar,
    IonTabButton,
    CyanPageWrapper,
  },
  setup() {
    return {
      flagOutline,
      mailOpenOutline,
      trophyOutline
    };
  },
  data() {
    return {};
  },
  computed: {
    junta(): string {
      return (this as any).$route.params.junta || '??';
    },
    municipio(): string {
      if (this.seed in municipios) {
        const m = municipios[(this as any).seed];
				return  m.nombre + ', ' + m.nombrePadre;
      }
      return '';
    },
    baseUrl(): string {
      const p = (this as any).$route.params;

      return '/agente/' + p.ambito + '/' + p.junta + '/';

    },
    seed(): any {
      return (this as any).$route.params.ambito || "SV";
    },

    backRoute() {
      const u = store.state.userData.nivel || "";
      const seed = this.seed as string;

      // Si hay más de una junta en el centro implicado,
      // a la elección de junta

      const acj = store.state.allowedCentrosJuntas[seed];

      if (acj) {
        const j = (this as any).$route.params.junta;
        if (
          acj.datosJuntas &&
          acj.datosJuntas[j] &&
          acj.datosJuntas[j].centro_id &&
          acj.mapaCentrosJuntas[acj.datosJuntas[j].centro_id] &&
          acj.mapaCentrosJuntas[acj.datosJuntas[j].centro_id].length > 2
        )
          return "/agentePickJunta/" + seed + "/" + acj.datosJuntas[j].centro_id;

        // Si hay más de un centro, a la elección de centro

        if (acj.centros.length > 1) return "/agentePickCentro/" + seed;
      } // ACJ
      // Si el usuario tiene nivel municipal, a home; si no, a pickMpio

      if (u.length != 6) return "/agentePickMpio/" + seed.substr(0, 4);

      return "/home";
    },
  },
  methods: {
    telefonoGuay(t: string) {
      return t.substr(0, 4) + "-" + t.substr(4);
    },
  },
});
</script>

<style scoped>
.nombre {
  font-weight: 500;
  font-size: 120%;
  color: var(--ion-color-primary);
}
.dato {
  color: var(--cyan-dark-grey);
  font-weight: 500;
  padding-left: 1.6em;
  text-indent: -1.6em;
}
.dato span {
  color: var(--ion-color-primary);
  font-weight: normal;
}
.votante {
  margin: 5px 0;
  border: 1px solid var(--cyan-light-grey);
  border-radius: 15px;
  background: white;
  padding: 0 5px;
}

.dato.cc-ok {
  color: var(--ion-color-success);
}

.dato.cc-rejected {
  color: var(--ion-color-danger);
}

.dato.cc-pending {
  color: var(--ion-color-medium);
}

.dato ion-icon {
  font-size: 110%;
  padding: 0 0 0 3px;
  position: relative;
  top: 3px;
}
</style>